/**
 * Redux store setup.
 */

import { createStore, combineReducers } from 'redux';

// Logger with default options
// import logger from "redux-logger";

import toast from './reducers/toast';
import user from './reducers/user';
import model from './reducers/model';

const reducers = combineReducers({
  user,
  toast,
  model,
});

export default function configureStore(initialState) {
  const store = createStore(reducers, initialState); //, applyMiddleware(logger));
  return store;
}
