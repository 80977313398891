import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  Switch,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: '50px',
  },
}));

const CookieSettingsModal = ({
  openModal,
  handleCloseModal,
  cookieSettings,
  setCookieSettings,
  handleAcceptCookies,
}) => {
  const classes = useStyles();
  const changeChangeSettings = (name, value) => {
    if (cookieSettings.indexOf(name) === -1) {
      setCookieSettings([...cookieSettings, name]);
    } else {
      const filtered = cookieSettings.filter((item) => item !== name);
      setCookieSettings(filtered);
    }
  };

  return (
    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal} disableBackdropClick>
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        Cookie Settings
      </DialogTitle>
      <DialogContent dividers>
        <List>
          <ListItem classes={classes}>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Necessary"
              secondary="Necessary cookies are required to enable the basic features of this site, such as providing secure log-in or adjusting your consent preferences. These cookies do not store any personally identifiable data."
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                // onChange={handleToggle('wifi')}
                checked={true}
                inputProps={{ 'aria-labelledby': 'switch-list-label-wifi' }}
              />
            </ListItemSecondaryAction>
          </ListItem>
          <ListItem>
            <ListItemText
              id="switch-list-label-wifi"
              primary="Analytics"
              secondary="Analytical cookies are used to understand how visitors interact with the website. These cookies help provide information on metrics such as the number of visitors, bounce rate, traffic source, etc."
            />
            <ListItemSecondaryAction>
              <Switch
                edge="end"
                onChange={(e) => changeChangeSettings('analytics', e.target.checked)}
                checked={cookieSettings.indexOf('analytics') !== -1}
              />
            </ListItemSecondaryAction>
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleAcceptCookies} color="primary" variant="contained">
          Accept Selected Cookies
        </Button>
        <Button onClick={handleCloseModal} variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieSettingsModal;
