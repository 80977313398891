import React, { useEffect, useState } from 'react';

import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';

import { checkCookieExist, setCookie, getCookie } from '../../helpers/utils';
import CookieSettingsModal from './CookieSettingsModal';
import RejectConfirmModal from './RejectConfirmModal';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '30px 20px',
    boxShadow: '0px -1px 7px 1px rgba(8, 8, 8, 0.15)',
  },
  boxWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  boxText: {
    fontSize: '15px',
    flex: 1,
  },
  boxActions: {
    width: '30%',
    '& > *': {
      margin: theme.spacing(2),
    },
  },
}));

const CookieConsent = () => {
  const classes = useStyles();

  const [showDrawer, setShowDrawer] = useState(false);
  const [showCookieModal, setShowCookieModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);

  const [cookieSettings, setCookieSettings] = useState(['necessary', 'analytics']);

  useEffect(() => {
    handleCheckCookie();
  }, []);

  const handleCheckCookie = () => {
    // check cookie consent and show drawer
    if (checkCookieExist('cookieConsent')) {
    } else {
      setShowDrawer(true);
    }
    if (checkCookieExist('cookieSettings')) {
      const settings = getCookie('cookieSettings');
      setCookieSettings(JSON.parse(settings));
    }
    // hit api to store in database
  };

  const handleAcceptCookies = () => {
    setCookie('cookieConsent', true);
    setCookie('cookieSettings', JSON.stringify(cookieSettings));
    setShowDrawer(false);
    setShowCookieModal(false);
  };

  return (
    <>
      <Drawer
        anchor={'bottom'}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        classes={classes}
        variant="persistent"
      >
        <Box className={classes.boxWrapper}>
          <Box className={classes.boxText}>
            <Typography variant="h6" gutterBottom>
              We value your privacy
            </Typography>
            We use cookies to enhance your browsing experience, serve personalized content, and analyze our traffic. By
            clicking "Accept All", you consent to our use of cookies.
          </Box>
          <Box className={classes.boxActions}>
            <Button variant="contained" color="primary" onClick={() => handleAcceptCookies()}>
              Accept All
            </Button>
            <Button variant="outlined" color="primary" onClick={() => setShowRejectModal(true)}>
              Reject
            </Button>
            <Button color="primary" onClick={() => setShowCookieModal(true)}>
              Cookie Settings
            </Button>
          </Box>
        </Box>
        {console.log('cookieSettings-->', cookieSettings)}
        <CookieSettingsModal
          openModal={showCookieModal}
          handleCloseModal={() => setShowCookieModal(false)}
          setCookieSettings={setCookieSettings}
          cookieSettings={cookieSettings}
          handleAcceptCookies={handleAcceptCookies}
        />

        <RejectConfirmModal
          openModal={showRejectModal}
          handleCloseModal={() => setShowRejectModal(false)}
          setShowCookieModal={setShowCookieModal}
        />
      </Drawer>
    </>
  );
};

export default CookieConsent;
