const Loader = () => {
  return (
    <div class="app-loader">
      <div className="loader-block block-blue"></div>
      <div className="loader-block block-yellow"></div>
      <div className="loader-block block-red"> </div>
      <div className="loader-block block-green"> </div>
      <div className="loader-text">Loading..</div>
    </div>
  );
};

export default Loader;
