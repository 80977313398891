import React from 'react';

import { CssBaseline, StylesProvider } from '@material-ui/core';
// eslint-disable-next-line import/order
import { Provider as ReduxProvider } from 'react-redux';

import './App.css';
import './custom-style.css';
import './form.css';

// eslint-disable-next-line import/order
import { LicenseManager } from 'ag-grid-enterprise';

import APP_CONFIGS from './app-config';
import Theme from './components/Theme';
import Toast from './components/Toast';
import Main from './Main';
import Routes from './Routes';
import configureStore from './store/index';
import CookieConsent from './components/CookieConsent';

LicenseManager.setLicenseKey(APP_CONFIGS.AG_GRID_LICENCE_KEY);

const reduxStore = configureStore({
  user: {
    user_set: false,
  },
});

export default function App() {
  return (
    <>
      <Theme>
        <StylesProvider injectFirst>
          <CssBaseline />
          <ReduxProvider store={reduxStore}>
            <Main>
              <CookieConsent />
              <Toast />
              <Routes />
            </Main>
          </ReduxProvider>
        </StylesProvider>
      </Theme>
    </>
  );
}

/**
 * Material Icons: https://v4.mui.com/components/material-icons/
 * */
