import React from 'react';

import { Redirect, Route } from 'react-router-dom';
import { getAppBaseUrl } from '../helpers/utils';

const PublicRoute = ({ component: Component, ...otherProps }) => {
  return (
    <Route
      {...otherProps}
      render={(props) =>
        localStorage.getItem('token') ? (
          <Redirect to={{ pathname: `${getAppBaseUrl()}/home` }} />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
