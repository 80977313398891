const modelReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SET_MODEL': {
      return {
        ...state,
        ...state.model,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

const setModel = (model) => ({
  type: 'SET_MODEL',
  payload: model,
});

export default modelReducer;
export { setModel };
