const DEFAULT_TIMEOUT = 2000;
const initialState = {
  text: '',
  open: false,
  messageTimeout: DEFAULT_TIMEOUT,
};

const toastReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_TOAST': {
      return {
        ...state,
        error: action.error,
        open: action.open,
        text: action.text,
        key: action.key,
        messageTimeout: action.messageTimeout,
        messageAction: action.messageAction,
      };
    }
    case 'HIDE_TOAST': {
      return {
        text: '',
        open: false,
        key: action.key,
      };
    }
    default:
      return state;
  }
};

const addToast = (message) => {
  return {
    type: 'ADD_TOAST',
    open: true,
    text: message.text,
    key: Date.now(),
    error: message.error ?? false,
    messageTimeout: message.messageTimeout || DEFAULT_TIMEOUT,
    messageAction: message.messageAction || null,
  };
};

const hideToast = () => {
  return {
    key: Date.now(),
    type: 'HIDE_TOAST',
  };
};

export default toastReducer;
export { addToast, hideToast };
