import React from 'react';

import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { connect } from 'react-redux';

import { hideToast } from '../store/reducers/toast';

const Toast = (props) => {
  const { toast, hideToast } = props;
  const severity = typeof toast.error === 'string' ? toast.error : toast.error ? 'error' : 'success';

  return (
    <Snackbar key={toast.key} open={toast.open} autoHideDuration={6000} onClose={hideToast}>
      <Alert severity={severity} onClose={hideToast}>
        {toast.text}
      </Alert>
    </Snackbar>
  );
};

const mapStateToProps = (state) => ({ toast: state.toast });
const mapDispatchToProps = (dispatch) => ({
  hideToast: () => dispatch(hideToast()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
