import { useEffect, useState } from 'react';

import axios from 'axios';
import { useAsyncEffect } from 'use-async-effect';

import { setEnvs } from './helpers/utils';

const Main = ({ setEnvVars, ...props }) => {
  // const [evars, setEvars] = useState(null);
  useAsyncEffect(async (isMounted) => {
    let serverUrl = '';
    if (window.location.hostname.includes('localhost')) {
      serverUrl = 'http://localhost:5001';
    }

    const envResponse = await axios.get(`${serverUrl}/env/`);
    setEnvs(envResponse.data);
    // setEvars(envResponse.data);
    if (!isMounted()) {
      return;
    }
  }, []);

  // if (evars) {
  return props.children;
  // } else {
  //   return false;
  // }
};

export default Main;
