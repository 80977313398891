import Paper from '@material-ui/core/Paper';
import Cookies from 'js-cookie';
import * as ReactDragable from 'react-draggable';
import numeral from 'numeral';
import { startCase } from 'lodash';
import { HiOutlineTrash } from 'react-icons/hi';
import Konva from 'konva';

/* Extract query param from url.
 * location object comes from react-router.
 */
export function getQueryParam(location, baseUrl, queryKey) {
  if (location && location.pathname === baseUrl) {
    const query = new URLSearchParams(location.search);
    return query.get(queryKey);
  }
  return null;
}

/* Get the current epoch time */
export function getEpochNow() {
  let d = new Date();
  return (d.getTime() - d.getMilliseconds()) / 1000;
}

export function titleCase(str) {
  return str
    .toLowerCase()
    .split(' ')
    .map(function (word) {
      return word.replace(word[0], word[0]?.toUpperCase());
    })
    .join(' ');
}
export const getAppBaseUrl = () => {
  return '/dashboard';
};

export const setEnvs = (data) => {
  localStorage.setItem('envs', JSON.stringify(data));
};
export const getEnvs = () => {
  const envs = localStorage.getItem('envs');
  if (envs) {
    return JSON.parse(envs);
  } else {
    return null;
  }
};

// Fetch key based on pathName in params
export const getRoutePathKey = (routes, pathname) => {
  const index = routes.findIndex((route) => route.value === pathname);
  if (index !== -1) {
    return routes[index].key;
  } else {
    return null;
  }
};

export const catchError = (err, setFormError) => {
  if (err.response) {
    // client received an error response (5xx, 4xx)
    if (err.response.data.message) {
      setFormError(err.response.data.message);
    } else {
      setFormError(err.response.statusText + '. Contact administrator.');
    }
  } else if (err.request) {
    // client never received a response, or request never left
    setFormError('Failed to fetch pre-requisites. Not able to send the request. Contact administrator.');
  } else {
    setFormError('Failed to fetch pre-requisites. Some error occurred. Contact administrator.');
  }
};

export const capitalizeString = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

// function to replace underscore from string with space
export const replaceUnderscoreWithSpace = (str) => {
  return str ? str.replace(/_/g, ' ') : '';
};

export const PaperComponent = (props) => {
  return (
    <ReactDragable handle="#customized-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </ReactDragable>
  );
};

export const removeAsterick = (text) => {
  return text ? text.replace('*', '') : '';
};

export const emailRegularExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

export const checkCookieExist = (cookieName) => {
  return Cookies.get(cookieName) !== undefined;
};
export const setCookie = (cookieName, value) => {
  // Cookies.set(cookieName, value, { expires: 365 });
  // let domain = getDomain(window?.location?.href);
  // Cookies.set(cookieName, value, { expires: 365, path: '/', domain: `${domain}` });

  const d = new Date();
  const exdays = 365;
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  let expires = ';expires=' + d.toUTCString();
  if (document) {
    let domain = getWebsiteDomain();
    domain = `domain=${domain};`;
    document.cookie = cookieName + '=' + value + expires + `;path=/;` + domain;
  }
};
export const getCookie = (cookieName) => {
  return Cookies.get(cookieName);
};

const getWebsiteDomain = () => {
  let domain = '';
  if (window.location.hostname.includes('localhost')) {
    domain = 'localhost';
  } else if (window.location.hostname.includes('dev')) {
    domain = '.np-artificio.com';
  } else if (window.location.hostname.includes('artificio.ai')) {
    domain = '.artificio.ai';
  }
  return domain;
};

function result(format, key = '.00') {
  const isInteger = format.includes(key);

  return isInteger ? format.replace(key, '') : format;
}

export function fShortenNumber(number) {
  const format = number ? numeral(number).format('0.00a') : '';

  return result(format, '.00');
}

export const titleCasedHeader = (header = '') => {
  return header.split('_').map(startCase).join(' ');
};

export const parseNumber = (string = '') => {
  const sanitizedString = string?.match?.(/[0-9,.]+/)?.[0]?.replace(/,/g, '');
  const number = Number.parseFloat(sanitizedString);

  return Number.isNaN(number) ? string : number;
};

/*  
  function: convertObjectToString
  description: converts a  array of objects to commna seperated string
  params: {1:[1,3],1: [5,8]}
  return: "1-3, 5-8" 
*/
export const convertObjectToString = (obj) => {
  const ranges = Object.entries(obj).map(([key, [start, end]]) => {
    let rangeString = '';
    if (start || start === 0) {
      rangeString = `${start}`;
    }
    if (rangeString && end) {
      rangeString = `${rangeString}-${end}`;
    }
    return rangeString;
  });
  return ranges.length > 0 ? ranges.join(',') : null;
};

/*  
  function: convertStringToObject
  description: converts a string to array of objects
  params: "1-3, 5-8"
  return: {1:[1,3],1: [5,8]}
*/
export const convertStringToObject = (str) => {
  const result = {};
  if (!str) {
    return result;
  }
  const ranges = str.split(',');
  ranges.forEach((range, index) => {
    const [start, end] = range.split('-').map(Number);
    const key = (index + 1).toString();

    if (!result[key]) {
      result[key] = end ? [start, end] : [start];
    } else {
      if (end) {
        result[key].push(start, end);
      } else {
        result[key].push(start);
      }
    }
  });

  return result;
};

export const perPageOptions = [50, 100, 250, 500];

export const getTemplateStatus = (layers) => {
  if (layers && layers.length == 3) {
    const blankLayer = layers.find((layer) => layer.attrs.type === 'TEMPLATE');
    if (blankLayer?.attrs?.image_url?.includes('a4-size')) {
      return 'blank';
    } else {
      return '';
    }
  } else if (layers && layers.length == 2) {
    return 'empty';
  } else {
    return '';
  }
};

export const validateEmail = (emailAddress) => {
  const match = emailAddress.match(emailRegularExp);

  let isValid = true;
  if (!match) {
    isValid = false;
  }
  return isValid;
};

export const defaultSendEmailThroughList = [
  {
    label: 'Email Group',
    value: 'email_list',
  },
  {
    label: 'Data Series',
    value: 'dataseries',
  },
  {
    label: 'Datagroup',
    value: 'datagroup',
  },
  {
    label: 'Formview',
    value: 'formview',
  },
  {
    label: 'Fileview',
    value: 'fileview',
  },
  {
    label: 'App Design',
    value: 'app_design',
  },
  {
    label: 'Table',
    value: 'table',
  },
];

export const designUsageList = [
  { label: 'Pageview', value: 'dataview' },
  { label: 'Tableview', value: 'tableview' },
  { label: 'Data Series', value: 'dataseries' },
  { label: 'Formview', value: 'formview' },
  { label: 'Fileview', value: 'fileview' },
  { label: 'App Design', value: 'app_design' },
  { label: 'Others', value: 'others' },
];

// export const designUsageList = ['Dataview', 'Data Series', 'Formview', 'App Design', 'Others'];

export const handleModifyEmailCriteria = (value, setSendEmailThroughList) => {
  let filteredList = [];
  switch (value) {
    case 'dataview':
    case 'dataseries':
    case 'formview':
    case 'app_design':
    case 'tableview':
    case 'fileview':
      filteredList = defaultSendEmailThroughList.filter((obj) => obj.value.includes(value));
      console.log('filteredList', filteredList);
      if (value == 'dataview') {
        const datagroupOption = defaultSendEmailThroughList.filter((obj) => obj.value == 'datagroup');
        setSendEmailThroughList([datagroupOption[0], defaultSendEmailThroughList[0]]);
      } else if (value == 'formview') {
        const datagroupOption = defaultSendEmailThroughList.filter((obj) => obj.value == 'datagroup');
        const appDesignOption = defaultSendEmailThroughList.filter((obj) => obj.value == 'app_design');
        setSendEmailThroughList([appDesignOption[0], datagroupOption[0], defaultSendEmailThroughList[0]]);
      } else if (value == 'tableview') {
        const tableOption = defaultSendEmailThroughList.filter((obj) => obj.value == 'table');
        setSendEmailThroughList([tableOption[0], defaultSendEmailThroughList[0]]);
      } else {
        setSendEmailThroughList([...filteredList, defaultSendEmailThroughList[0]]);
      }
      break;
    case 'others':
      setSendEmailThroughList([defaultSendEmailThroughList[0]]);
      break;
    default:
      setSendEmailThroughList(defaultSendEmailThroughList);
  }
};

export const defaultWebFonts = [
  { value: 'Arial', label: 'Arial' },
  { value: '"Helvetica Neue", Helvetica, Arial, sans-serif', label: 'Helvetica Neue' },
  { value: 'Georgia, serif', label: 'Georgia' },
  { value: '"Times New Roman", Times, serif', label: 'Times New Roman' },
  { value: 'Verdana, Geneva, sans-serif', label: 'Verdana' },
  { value: '"Trebuchet MS", Helvetica, sans-serif', label: 'Trebuchet MS' },
  { value: '"Courier New", Courier, monospace', label: 'Courier New' },
  { value: '"Lucida Console", Monaco, monospace', label: 'Lucida Console' },
  { value: 'Impact, Charcoal, sans-serif', label: 'Impact' },
  { value: '"Comic Sans MS", cursive, sans-serif', label: 'Comic Sans MS' },
];

export const rulesUsageList = [
  { label: 'Page data view', value: 'dataview' },
  { label: 'Table view', value: 'tableview' },
  { label: 'File view', value: 'file_view' },
  // { label: 'Form view', value: 'form_view' },
];

export const findFirstPageUploadedFile = (templatesArr) => {
  // Get the "file" value from the last object
  const lastFileValue = templatesArr[templatesArr.length - 1].file;

  // Find all objects with the same "file" value as the last index
  const matchingObjects = templatesArr.filter((obj) => obj.file === lastFileValue);

  // Find the object with the least "page_no" value from the filtered array
  const minPageNoObject = matchingObjects.reduce((minObj, currentObj) => {
    return currentObj.page_no < minObj.page_no ? currentObj : minObj;
  }, matchingObjects[0]);

  return minPageNoObject;
};

// This will return data like dataview detail api
export const makeFileViewDataArr = (dataGroups) => {
  const dataArr = [];
  const lineItems = [];
  Object.keys(dataGroups).forEach((dataGroup, index) => {
    // dont iterate over these 2 keys
    if (dataGroup !== 'line_items' && dataGroup !== 'datagroups') {
      // concat all the type of labels in one array
      const labels = [
        ...dataGroups[dataGroup]['assign_labels'],
        ...dataGroups[dataGroup]['extra_labels'],
        ...dataGroups[dataGroup]['selection_labels'],
      ].map((label) => label.name);

      const dataGroupName = dataGroups.datagroups[dataGroup];
      const obj = {
        group_name: dataGroupName,
        children: labels,
      };
      dataArr.push(obj);
    }
    if (dataGroup === 'line_items') {
      Object.keys(dataGroups['line_items']).map((lineItem) => {
        const obj = {
          table_name: lineItem,
          children: dataGroups['line_items'][lineItem],
        };
        lineItems.push(obj);
      });
    }
  });
  return { groups: dataArr, lineItems };
};

export const makeTableviewData = (data) => {
  const tableData = [];
  if (data && Object.keys(data).length > 0) {
    Object.keys(data).map((tableName) => {
      const obj = {
        table_name: tableName,
        children: data[tableName],
      };
      tableData.push(obj);
    });
  }
  return tableData;
};

export const showDeleteElement = (element, index, handleRemoveLayerConfirmation) => {
  console.log('element', element);
  const { name } = element.attrs;

  switch (name) {
    case 'TOOLTIP':
    case 'GRID':
      return null;
    default:
      return (
        <a href="#" onClick={(e) => handleRemoveLayerConfirmation(e, index)} className="delete-button">
          <HiOutlineTrash size={16} />
        </a>
      );
      break;
  }
};

export const autoNameErrorText = `Name cannot start with 'auto_' keyword`;

export const convertToGB = (dataInKb) => {
  if (dataInKb) {
    return (dataInKb / (1024 * 1024)).toFixed(4);
  } else {
    return dataInKb;
  }
};

export const fetchNodeParent = (currentNode) => {
  console.log('parent fetchNodeParent', hasGrandparent(currentNode));
  return hasGrandparent(currentNode);
};

const hasGrandparent = (node) => {
  if (
    !node ||
    !node.getParent() ||
    node.getParent() instanceof Konva.Layer ||
    node.getParent() instanceof Konva.Stage
  ) {
    return false; // No parent or node itself is at top level (no grandparent)
  }

  const parent = node.getParent();
  if (!parent.getParent() || parent.getParent() instanceof Konva.Layer || parent.getParent() instanceof Konva.Stage) {
    return parent; // Parent doesn't have a parent, so it's the grandparent
  }

  // Continue recursion if further levels need to be checked
  return hasGrandparent(parent);
};

export const getCustomDropRejectMessage = (rejectedFile, acceptedFiles, maxFileSize, user) => {
  console.log('rejected file->', rejectedFile, acceptedFiles);
  if (rejectedFile.size > maxFileSize) {
    return `The file is too large. Maximum file size is ${
      user && user.plan_name === 'trial' ? `${maxFileSize / 1024} Kb for trial users` : `${maxFileSize / 1048576} Mb`
    }`;
  } else if (!acceptedFiles.includes(rejectedFile.type)) {
    return `Invalid file type. only images and pdfs are allowed.`;
  }
};

export const uploadListoptions = {
  rowsPerPage: 20,
  rowsPerPageOptions: perPageOptions,
  jumpToPage: true,
  elevation: 0,
  filter: false,
  print: false,
  sortOrder: {
    name: 'created_at',
    direction: 'desc',
  },
  selectToolbarPlacement: 'none',
  responsive: 'vertical',
  pagination: false,
  setTableProps: () => {
    return {
      size: 'small',
    };
  },
  download: false,
  viewColumns: false,
  selectableRows: 'none',
};

export const removeUnderScoreAndCapitalize = (text) => {
  return (
    text
      // .split('.')[1]
      .split('_')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
  );
};
