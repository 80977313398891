import React, { Suspense, lazy } from 'react';

import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import Loader from './Loader';
import { getAppBaseUrl } from './helpers/utils';

const Activate = lazy(() => import('./routes/Activate'));
const Dashboard = lazy(() => import('./routes/Dashboard'));
const ForgotPassword = lazy(() => import('./routes/ForgotPassword'));
const OnlineForms = lazy(() => import('./routes/OnlineForms'));
const ResetPassword = lazy(() => import('./routes/ResetPassword'));
const SignIn = lazy(() => import('./routes/SignIn'));
const SignUp = lazy(() => import('./routes/SignUp'));
const PdfDesignComponent = lazy(() => import('./components/PdfDesign/PdfDesignComponent'));
const PdfPreviewComponent = lazy(() => import('./components/PdfDesign/PdfPreviewComponent'));
const CreateSignatureComponent = lazy(() => import('./components/PdfDesign/CreateSignatureComponent'));
const PdfGetRequestedData = lazy(() => import('./components/PdfGetRequestedData/PdfGetRequestedData'));
const FormWithDataView = lazy(() => import('../src/routes/DataValidation/FormView/FormWithDataView'));

const Routes = () => {
  return (
    <Router>
      <Suspense fallback={<Loader />}>
        <Switch>
          <PrivateRoute path={getAppBaseUrl()} component={Dashboard} />
          <PrivateRoute path="/pdf-design" component={PdfDesignComponent} />
          <PrivateRoute exact path="/pdf-preview" component={PdfPreviewComponent} />
          <PrivateRoute path="/create-signature" component={CreateSignatureComponent} />
          <PrivateRoute exact path="/view-form" component={FormWithDataView} />
          <PublicRoute path="/sign-in" component={SignIn} />
          <PublicRoute path="/sign-up" component={SignUp} />
          <Route path="/activate" component={Activate} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/online-forms" component={OnlineForms} />
          <Route path="/online-forms" component={OnlineForms} />
          <Route path="/pdf-form" component={PdfGetRequestedData} />

          <Redirect to={`${getAppBaseUrl()}/home`} />
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
