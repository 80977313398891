import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  ListSubheader,
  Switch,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingRight: '50px',
  },
}));

const RejectConfirmModal = ({ openModal, handleCloseModal, setShowCookieModal }) => {
  const classes = useStyles();
  const handleShowCookieModal = () => {
    handleCloseModal();
    setShowCookieModal(true);
  };
  return (
    <Dialog onClose={handleCloseModal} aria-labelledby="customized-dialog-title" open={openModal} disableBackdropClick>
      <DialogTitle id="customized-dialog-title" onClose={handleCloseModal}>
        Are you sure you want to reject?
      </DialogTitle>
      <DialogContent dividers>
        <p>
          If you reject all the cookies, You wont be able to operate this website as "Necessary" cookies are required to
          main the session in this website.
        </p>
        <p>You can goto cookie settings and disable other cookies and Accept "Necessary" cookies.</p>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleShowCookieModal} color="primary" variant="contained">
          Goto cookie settings
        </Button>
        <Button onClick={handleCloseModal} variant="outlined">
          Reject cookies
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectConfirmModal;
